<template>
	<div id="box">
		<div id="CWlogbox">
			<p>财务票据登录</p>
			<el-input placeholder="请输入账号" v-model="acc" clearable>
			</el-input>
			<el-input placeholder="请输入密码" type='password' v-model="pass" clearable>
			</el-input>
			  <el-button type="primary" @click="loginfun">登录</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				acc: '',
				pass:""
			}
		},
		methods:{
			loginfun(){
				if(this.acc==''){
					this.$message.error('请输入账号！')
					return
				}
				if(this.pass==''){
					this.$message.error('请输入密码！')
					return
				}
				this.$router.push('/invoice')
			}
		}
		
	}
</script>

<style scoped="scoped" lang="less">
	#box{
		display: flex;
		height: 100vh;
		width: 100vw;
		background-image: url('http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/cwbgc.jpg');
		background-size: 100% 100%;
		justify-content: center;
		align-items: center;
	}
	#CWlogbox{
		width: 400px;
		height: 300px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		p{
			font-size: 36px;
			margin: 0;
			font-weight: bold;
		}
	}
</style>
